"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializeClientTypeChecker = initializeClientTypeChecker;
class ClientTypeChecker {
    constructor(config) {
        this.config = config;
        this.timedOut = false;
        this.img = new Image();
        this.timer = null;
        // Handle successful image load (internal client)
        this.handleImageLoad = () => {
            if (!this.timedOut) {
                this.clearTimer();
                this.setInternalClient();
            }
        };
        // Handle image load error (external client)
        this.handleImageError = () => {
            if (!this.timedOut) {
                this.clearTimer();
                this.setExternalClient();
            }
        };
        this.initializeCheck();
    }
    // Initialize the client type check
    initializeCheck() {
        const storedResult = sessionStorage.getItem('brokenLinkClientType');
        if (storedResult) {
            this.applyStoredResult(storedResult);
        }
        else {
            this.setTimer();
            this.loadImage();
        }
    }
    // Apply a stored result
    applyStoredResult(result) {
        if (result === 'internal') {
            this.setInternalClient();
        }
        else if (result === 'external') {
            this.setExternalClient();
        }
    }
    // Set a timeout for image loading
    setTimer() {
        this.timer = window.setTimeout(() => {
            this.timedOut = true;
            this.cancelImageLoad();
            this.setExternalClient();
        }, this.config.checkTimeout);
    }
    // Load the image and handle success or failure
    loadImage() {
        this.img.onload = this.handleImageLoad;
        this.img.onerror = this.handleImageError;
        this.img.src = this.config.checkUrl;
    }
    // Clear the timer if image loads or errors before timeout
    clearTimer() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }
    // Cancel image loading on timeout
    cancelImageLoad() {
        this.img.src = ''; // Cancel image loading
    }
    // Mark as internal client
    setInternalClient() {
        document.dispatchEvent(new CustomEvent('brokenLinkContextDetectionInternal'));
        document.body.classList.add(this.config.successClass);
        sessionStorage.setItem('brokenLinkClientType', 'internal');
    }
    // Mark as external client
    setExternalClient() {
        // Execute custom event
        document.dispatchEvent(new CustomEvent('brokenLinkContextDetectionExternal'));
        document.body.classList.add(this.config.failedClass);
        this.applyDomainRestrictions();
        sessionStorage.setItem('brokenLinkClientType', 'external');
    }
    // Apply domain restrictions to domains in the domain list
    applyDomainRestrictions() {
        this.config.domains.forEach(domain => {
            const elements = document.querySelectorAll(`a[href*="${domain}"]`);
            elements.forEach(element => {
                this.addUnavailableClass(element);
                this.preventDefaultOnClick(element);
                if (this.config.isToolTipActive) {
                    this.addTooltip(element);
                }
                if (this.config.isModalActive) {
                    this.addModalAttributes(element);
                }
            });
        });
        this.reindexModals();
    }
    addUnavailableClass(element) {
        element.classList.add('broken-link-detector-link-is-unavailable');
    }
    preventDefaultOnClick(element) {
        element.addEventListener("click", (event) => {
            event.preventDefault();
        });
    }
    addTooltip(element) {
        element.setAttribute("data-tooltip", this.config.tooltip);
    }
    addModalAttributes(element) {
        element.setAttribute("data-open", "modal-broken-link");
        element.addEventListener("click", () => {
            const modalButton = document.getElementById("modal-broken-link-button");
            if (modalButton) {
                modalButton.setAttribute("href", element.getAttribute("href") || "#linknotfound");
            }
        });
    }
    reindexModals() {
        document.dispatchEvent(new CustomEvent('reindexModals'));
    }
}
// @ts-ignore Function to initialize client type checker  
function initializeClientTypeChecker(brokenLinkContextDetectionData) {
    document.addEventListener("DOMContentLoaded", () => {
        new ClientTypeChecker(brokenLinkContextDetectionData);
    });
}
// @ts-ignore
initializeClientTypeChecker(brokenLinkContextDetectionData);
